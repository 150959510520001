<template>
  <div
    class="inflar"
    style="background: #e2e2e2; background: url('https://steamcdn-a.akamaihd.net/steamcommunity/public/images/items/96100/f400290347611bd57a63728d10bc87131ad0d8a9.jpg');"
  >
    <div
      class="inflar"
      style="opacity: 0.8; background: linear-gradient(to right, #000000, #111);"
    >
      <div class="inflar centraliza" style="opacity: 1.0;">
        <v-flex xs10 md3>
          <div class="inflar-porcentagem centraliza">
            <FormLogin />
          </div>
          <!-- <div class="inflar-porcentagem centraliza">
            <v-btn
              text
              dark
              @click="$router.push('/signup/nosponsor')"
              class="fonte"
            >
              Criar Uma Conta Grátis
            </v-btn>
          </div> -->
          <ForgotPassword />
        </v-flex>
      </div>
    </div>
    <!-- <div style="position: absolute; bottom: 10px;" class="expande-horizontal centraliza column">
      <h5 class="fonte font-weight-light grey--text mb-1">Desenvolvido Por:</h5>
      <img height="40" src="https://firebasestorage.googleapis.com/v0/b/cosmos-home-site.appspot.com/o/8%201.png?alt=media&token=be454918-d24d-4129-8ade-b89de2ec10a5" alt="">
    </div> -->
  </div>
</template>

<script>
import FormLogin from "./components/FormLogin";
import ForgotPassword from "./components/ForgotPassword";
import FormSignup from "./components/FormSignup";

export default {
  components: {
    FormLogin,
    ForgotPassword,
    FormSignup
  }
};
</script>
