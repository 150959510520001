<template>
  <v-dialog v-model="getModalForgotPassword"> </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getModalForgotPassword"])
  },
  methods: {
    ...mapActions(["unsetModalForgotPassword"])
  }
};
</script>
