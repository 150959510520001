<template>
  <v-form
    style="border-radius: 10px; opacity: 1;"
    dark
    class="expande-horizontal wrap pa-3 centraliza"
    ref="form"
  >
    <v-flex class="py-3 pt-0" xs12 md10>
      <div class="expande-horizontal centraliza">
        <v-avatar size="60" style="border: 3px solid #fff;">
          <v-img
            src="https://i.pinimg.com/originals/7c/c7/a6/7cc7a630624d20f7797cb4c8e93c09c1.png"
          ></v-img>
        </v-avatar>
      </div>
    </v-flex>

    <v-flex class="py-3 pt-0" xs12 md10>
      <div class="expande-horizontal centraliza">
        <span
          class="fonteCash text-center white--text"
          :style="`color: ${$theme.primary}`"
          >Bem Vindo Admin!</span
        >
      </div>
    </v-flex>

    <v-flex xs12 md10>
      <v-text-field
        rounded
        solo
        dark
        label="Login"
        dense
        :rules="[v => !!v || 'Preencha este campo']"
        v-model="payload.username"
      ></v-text-field>
    </v-flex>

    <v-flex xs12 md10>
      <v-text-field
        solo
        dark
        rounded
        type="password"
        label="Senha"
        @keyup.enter="validaCampo"
        dense
        :rules="[v => !!v || 'Preencha este campo']"
        v-model="payload.password"
      ></v-text-field>
    </v-flex>

    <v-flex xs12 md10>
      <div class="expande-horizontal centraliza">
        <v-btn block rounded dark color="grey darken-4" @click="validaCampo">
          <span class="fonte green--text" :style="`color: ${$theme.primary}`">
            entrar
          </span>
          <v-icon class="ml-2" color="green" size="18">
            mdi-check
          </v-icon>
        </v-btn>
      </div>
    </v-flex>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import ForgotPassword from "./ForgotPassword";
export default {
  components: {
    ForgotPassword
  },
  data() {
    return {
      payload: {
        username: "",
        password: ""
      }
    };
  },
  methods: {
    ...mapActions(["createSession"]),
    validaCampo() {
      if (this.$refs.form.validate()) {
        this.createSession(this.payload);
      }
    }
  }
};
</script>
